import React from 'react';
import './about.css';
import ME from '../../assets/me-about-2.png'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import Zoom from 'react-reveal/Zoom'
import Roll from 'react-reveal/Roll'
// import '../../components/decodingAnimation'

const About = () => {
  return (
    <section id='about'>
      <Zoom left cascade><h5>Get To Know</h5></Zoom>
      <Zoom right cascade><h2>About Me</h2></Zoom>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About_Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card_experience'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              {/* <small>3 Years</small> */}
            </article>
            <article className='about__card_anime'>
              <FiUsers className='about__icon' />
              <h5>Favorite Anime Character</h5>
              {/* <small>3 Years</small> */}
            </article>
            <article className='about__card_hobby'>
              <FaAward className='about__icon' />
              <h5>I Enjoy</h5>
              {/* <small>3 Years</small> */}
            </article>
          </div>

          <p>
            A self-driven, enthusiastic, reliable and focused individual with an agile mind, strong problem solving and organizational skills.
            I am passionate about developing and designing applications and systems that could bring a positive impact on the society.
            As Audrey Hepburn says, "Nothing is impossible, the word itself says I'm possible", I also believe that a task or a situation always
            seems impossible until it is done. A little progress each day is all we need, and it adds up to big results. Aside from developing applications, I like watching anime and traveling to new places. Feel free to checkout my favorite anime above.
          </p>
          <a href='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About