import React, {useEffect} from 'react'
import About from './components/about/About'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import Education from './components/education/Education'
import Experience from './components/experience/Experience'
import Projects from './components/projects/Projects'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Skills from './components/skills/Skills'
import LoadingSpinner from './components/loadingSpinner/LoadingSpinner'
import { Audio } from 'react-loader-spinner';

export const App = () => {

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     console.log('Initial Load')
  //   }, 1000);
  //   return (
  //     <LoadingSpinner />
  //   )
    
  // }, []);

  // <Audio height="80" width="80" radius="9" color="green" ariaLabel="loading" wrapperStyle wrapperClass />
  return (
    
    <>
    
    <Header />
    <Nav />
    <About />
    <Skills />
    <Experience />
    <Projects />
    <Education />
    <Testimonials />
    <Contact />
    <Footer />
    </>
  )
}

export default App;