import React from 'react'
import './skills.css'
import { AiOutlineHtml5, AiOutlineDeploymentUnit } from 'react-icons/ai'
import { IoLogoCss3 } from 'react-icons/io'
import { SiJavascript, SiRedux, SiSpringboot, SiAmazonaws, SiAmazons3 } from 'react-icons/si'
import { FaReact, FaNode, FaJava, FaPython } from 'react-icons/fa'
import { DiJavascript1, DiMongodb } from 'react-icons/di'
import { VscBracketDot } from 'react-icons/vsc'
import { GrMysql, GrSwift, GrStackOverflow } from 'react-icons/gr'
import { BsPatchCheckFill, BsGithub, BsFiles } from 'react-icons/bs'
import {ImTree} from 'react-icons/im'
import {TbFileDatabase} from 'react-icons/tb'
import {GiPoliceBadge} from 'react-icons/gi'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'

const Skills = () => {
  return (
    <section id='skills'>
      <Zoom left cascade><h5>What Skills I Have</h5></Zoom>
      <Zoom right cascade><h2>Tech Stack</h2></Zoom>
      <Fade bottom>
      <div className="container skills__container">
        <div className="skills__frontend">
          <div>
            <h3>Frontend Development</h3>
          </div>
          <div className="skills__content">
            <article className='skills__details'>
              <AiOutlineHtml5 className='skills__details-icons'/>
              <h4>HTML5</h4>
            </article>
            <article className='skills__details'>
              <IoLogoCss3 className='skills__details-icons'/>
              <h4>CSS3</h4>
            </article>
            <article className='skills__details'>
              <SiJavascript className='skills__details-icons'/>
              <h4>JavaScript ES6</h4>
            </article>
            <article className='skills__details'>
              <FaReact className='skills__details-icons'/>
              <h4>React JS</h4>
            </article>
            <article className='skills__details'>
              <SiRedux className='skills__details-icons'/>
              <h4>Redux</h4>
            </article>
          </div>
        </div>
        <div className="skills__backend">
          <div>
            <h3>Backend Development</h3>
          </div>
          <div className="skills__content">
            <article className='skills__details'>
              <FaNode className='skills__details-icons'/>
              <h4>Node JS</h4>
            </article>
            <article className='skills__details'>
              <DiJavascript1 className='skills__details-icons'/>
              <h4>Express JS</h4>
            </article>
            <article className='skills__details'>
              <VscBracketDot className='skills__details-icons'/>
              <h4>Rest</h4>
            </article>
            <article className='skills__details'>
              <SiSpringboot className='skills__details-icons'/>
              <h4>Spring</h4>
            </article>
          </div>
        </div>
        <div className='skills__cloud'>
          <div>
            <h3>Cloud</h3>
          </div>
          <div className="skills__content">
            <article className='skills__details'>
              <SiAmazonaws className='skills__details-icons'/>
              <h4>AWS</h4>
            </article>
            <article className='skills__details'>
              <BsFiles className='skills__details-icons'/>
              <h4>EC2</h4>
            </article>
            <article className='skills__details'>
              <SiAmazons3 className='skills__details-icons'/>
              <h4>S3</h4>
            </article>
            <article className='skills__details'>
              <ImTree className='skills__details-icons'/>
              <h4>Elastic Beanstalk</h4>
            </article>
            <article className='skills__details'>
              <AiOutlineDeploymentUnit className='skills__details-icons'/>
              <h4>CICD</h4>
            </article>
            <article className='skills__details'>
              <TbFileDatabase className='skills__details-icons'/>
              <h4>ElasticCache</h4>
            </article>
            <article className='skills__details'>
              <GrStackOverflow className='skills__details-icons'/>
              <h4>SQS & SNS</h4>
            </article>
            <article className='skills__details'>
              <GiPoliceBadge className='skills__details-icons'/>
              <h4>Route 53</h4>
            </article>
          </div>
        </div>
        <div className="skills__programmingLanguages">
          <div>
            <h3>Programming Languages</h3>
          </div>
          <div className="skills__content">
            <article className='skills__details'>
              <FaJava className='skills__details-icons'/>
              <h4>Java</h4>
            </article>
            <article className='skills__details'>
              <SiJavascript className='skills__details-icons'/>
              <h4>JavaScript</h4>
            </article>
            <article className='skills__details'>
              <FaPython className='skills__details-icons'/>
              <h4>Python</h4>
            </article>
            <article className='skills__details'>
              <GrSwift className='skills__details-icons'/>
              <h4>Swift</h4>
            </article>
            <article className='skills__details'>
              <BsPatchCheckFill className='skills__details-icons'/>
              <h4>C++</h4>
            </article>
            <article className='skills__details'>
              <BsPatchCheckFill className='skills__details-icons'/>
              <h4>RPGLE</h4>
            </article>
          </div>
        </div>
        <div className="skills__database">
          <div>
            <h3>Databases</h3>
          </div>
          <div className="skills__content">
            <article className='skills__details'>
              <DiMongodb className='skills__details-icons'/>
              <h4>MongoDB</h4>
            </article>
            <article className='skills__details'>
              <GrMysql className='skills__details-icons'/>
              <h4>MySQL</h4>
            </article>
          </div>
        </div>
        <div className="skills__versionControl">
          <div>
            <h3>Version Control</h3>
          </div>
          <div className="skills__content">
            <article className='skills__details'>
              <BsGithub className='skills__details-icons'/>
              <h4>Github</h4>
            </article>
          </div>
        </div>
      </div>
      </Fade>
    </section>
  )
}

export default Skills