import React from 'react'
import './projects.css'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import IRIS from '../../assets/IRIS.png'
import Yelp from '../../assets/YELP.png'
import AED from '../../assets/AED.png'
import DMDD from '../../assets/DMDD.png'
import PSA from '../../assets/PSA.jpg'
import Portfolio from '../../assets/background-1.jpg'
import BlogPost from '../../assets/BlogPost.png'
import { FiExternalLink } from 'react-icons/fi'

const data = [
  {
    id: 1,
    image: IRIS,
    title: 'Instant Response Immediate Support',
    github: 'https://github.com/swapnilvise/Web-Design-User-Experience---Vise-Swapnil'
  },
  {
    id: 2,
    image: Yelp,
    title: 'YelpCamp',
    github: ''
    // github: 'https://github.com/swapnilvise/Web-Design-User-Experience---Vise-Swapnil'
  },
  {
    id: 3,
    image: AED,
    title: 'Covid-19 Patient Management System',
    github: 'https://github.com/swapnilvise/AED-Final-Project'
  },
  {
    id: 4,
    image: PSA,
    title: 'TicTacToe Game in Java',
    github: 'https://github.com/swapnilvise/INFO6205_FinalProject'
  },
  {
    id: 5,
    image: DMDD,
    title: 'Covid-19 Resource Management System',
    github: 'https://github.com/swapnilvise/DMDD-Project'
  },
  {
    id: 6,
    image: BlogPost,
    title: 'BlogPost - Blog Posting Application',
    github: 'https://github.com/swapnilvise/BlogPost---A-Blog-Posting-Application'
  }
]


const Projects = () => {
  return (
    <section id='projects'>
      <Zoom left cascade><h5>My Recent Work</h5></Zoom>
      <Zoom right cascade><h2>Projects</h2></Zoom>

      <Fade bottom>
        <div className="container projects__container">
          {data.map(({ id, image, title, github }) => {
            return (

              <article key={id} className='projects__item'>
                <div className="project__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="projects__items-cta">
                  <a href={github} className='btn' target='_blank'><FiExternalLink /></a>
                </div>
              </article>
            )
          })
          }
        </div>
      </Fade>

    </section>
  )
}

export default Projects