import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div className="footer__copyright">
        <small>&copy; 2022 My Portfolio Website | Designed and Developed by <a href='https://www.linkedin.com/in/swapnilvise/'>Swapnil Vise</a> | All Rights Reserved </small>  
      </div>
    </footer>
  )
}

export default Footer