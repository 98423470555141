import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFTkIyRsClZuoN3V7ChlejUOCvxWRKc4w",
  authDomain: "portfoliowebsite-swapnilvise.firebaseapp.com",
  projectId: "portfoliowebsite-swapnilvise",
  storageBucket: "portfoliowebsite-swapnilvise.appspot.com",
  messagingSenderId: "163623162286",
  appId: "1:163623162286:web:974bc91aaf22f5a5dd783f",
  measurementId: "G-5804T858T5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(<App/>,document.querySelector("#root"));