import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {ImGithub} from 'react-icons/im'
import {BsInstagram} from 'react-icons/bs'

const headerSocials = () => {
  return (
    <div className='header__socials'>
        {/* _blank opens this link in a new tab */}
        <a href='https://www.linkedin.com/in/swapnilvise/' target='_blank'><BsLinkedin/></a>
        <a href='https://github.com/swapnilvise' target='_blank'><ImGithub/></a>
        <a href='https://www.instagram.com/swapnil_11/?hl=en' target='_blank'><BsInstagram/></a>
    </div>
  )
}

export default headerSocials