import React, { Component } from 'react'
import './experience.css'
import Fade from 'react-reveal/Fade'
import { FaGraduationCap } from 'react-icons/fa'
import { MdOutlineWorkOutline } from 'react-icons/md'
import mphasisLogoImage from '../../assets/mphasisLogo.png'
import Slide from 'react-reveal/Slide';
import neuLogo from '../../assets/neuLogo.svg'
import sppuLogo from '../../assets/puneUniversity.png'
import coeLogo from '../../assets/coelogo.png'
import Zoom from 'react-reveal/Zoom'

const Experience = () => {
  return (
    <section id='experience'>
      <Zoom top cascade><h2>Work and Education</h2></Zoom>
      <div className="container experience__container">
        <div className='experienceDottedLine'>
          <Fade bottom>
            <div className="experience__icon">
              <MdOutlineWorkOutline />
            </div>
            <div className='experience__content_1'>
              <h5 className='period'>September 2022 - Present</h5>
              <h2 className='companyName'>Northeastern University, College of Engineering</h2>
              <h4 className='location'>Boston, MA</h4>
              <h4 className='role'>Graduate Teaching Assistant</h4>
              <small>
                • Mentoring a class of 80 students helping them better understand the fundamental concepts of DBMS and their application,
                data modeling and data normalization within computer systems by providing meaningful feedback besides grading assignments<br />
                • Assisted professor with instructional responsibilities for the class and served as a liaison between students and professor
              </small>
            </div>
          </Fade>
          <Fade bottom>
            <div className="experience__content_2">
              <h5 className='period'>August 2018 - July 2021</h5>
              <h2 className='companyName'>Mphasis</h2>
              <h4 className='location'>Pune, India</h4>
              <h4 className='role'>Software Engineer</h4>
              <small>
              •	Developed new features as per user requirements in an Agile Development process model utilizing a test-driven development
              approach by modifying existing application and website using Java and JavaScript reducing manual efforts by 60%<br />
              •	Redesigned code in NodeJS and ReactJS for doing background check on customer which helped automate 80% of the work<br />
              •	Created/modified stored procedures in SQL used by other applications connected to the system<br />
              •	Configured and managed the data setup using SQL Server Management Studio for smooth execution of applications<br /> 
              •	Incorporated 40 plus new insurance products into the application which resulted in profit of approximately 200k dollars<br /> 
              •	Conducted process assessment and devised new workflow to increase system accuracy and speed by 30%<br />
              </small>
            </div>
          </Fade>
        </div>
        <Slide right>
          <div className="experience__logo_1">
            <img src={coeLogo} alt='experience__image' />
          </div>
        </Slide>
        <Slide right>
          <div className="experience__logo_2">
            <img src={mphasisLogoImage} alt='experience__image' />
          </div>
        </Slide>
      </div>
      <div className='container education__container'>
        <div className='educationDottedLine'>
          <div className="education__icon">
            <FaGraduationCap />
          </div>
          <Fade bottom>
            <div className="education__content_1">
              <h5 className='period'>September 2021 - Present</h5>
              <h2 className='universityName'>Northeastern University</h2>
              <h4 className='location'>Boston, MA</h4>
              <h4 className='degree'>Master of Science Information Systems</h4>
              <small>
                • Program Structures and Algorithms <br />
                • Web Design and User Experience Engineering <br />
                • Web Development Tools and Methods<br />
                • Application Engineering and Development <br />
                • Smart Phone Based Web Development <br />
                • Software Quality Control and Management <br />
                • Data Management and Database Design <br />
              </small>
            </div>
            <div className="education__content_2">
              <h5 className='period'>August 2014 - July 2018</h5>
              <h2 className='universityName'>Savitribai Phule Pune University</h2>
              <h4 className='location'>Pune, India</h4>
              <h4 className='degree'>Bachelor of Engineering, Electronics and Telecommunication Engineering</h4>
              <small>
                • Object Oriented Programming Language <br />
                • Data Structures and Algorithms <br />
                • Computer Organization<br />
              </small>
            </div>
          </Fade>
        </div>
        <Slide left>
          <div className="neu__logo">
            <img src={neuLogo} alt='neu__image' />
          </div>
        </Slide>
        <Slide left>
          <div className="sppu__logo">
            <img src={sppuLogo} alt='sppu__image' />
          </div>
        </Slide>
      </div>
    </section >
  )
}

export default Experience