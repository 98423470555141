import React from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md'
import { AiOutlinePhone } from 'react-icons/ai'
import { GoLocation } from 'react-icons/go'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'

const Contact = () => {
  return (
    <section id='contact'>
      <Zoom left><h5>Get In Touch</h5></Zoom>
      <Zoom right><h2>Contact Me</h2></Zoom>
      <div className="container contact__container">
        <div className="contact__options">
          <Fade left>
            <article className="contact__option">
              <MdOutlineEmail className='contact__option-icon' />
              <h4>Email</h4>
              <h5><a href='mailto:swapnilvise1109@gmail.com'>swapnilvise1109@gmail.com</a></h5>
            </article>
          </Fade>
          <Fade bottom>
          <article className="contact__option">
            <AiOutlinePhone className='contact__option-icon' />
            <h4>Phone</h4>
            <h5><a>+1 (857) 437 9431</a></h5>
          </article>
          </Fade>
          <Fade right>
          <article className="contact__option">
            <GoLocation className='contact__option-icon' />
            <h4>Address</h4>
            <h5><a href='https://www.google.com/maps/place/Boston,+MA/@42.3142647,-71.1103673,11z/data=!3m1!4b1!4m5!3m4!1s0x89e3652d0d3d311b:0x787cbf240162e8a0!8m2!3d42.3600825!4d-71.0588801?hl=en'>Boston, Massachusetts</a></h5>
          </article>
          </Fade>
        </div>

      </div>
    </section >
  )
}

export default Contact