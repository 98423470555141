import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me_2.png'
import HeaderSocials from './headerSocials'
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import Typewriter from 'typewriter-effect';


const Header = () => {
  return (
    <header>
      <div className="container header_container">
        {/* <h5>Hello! I'm</h5> */}
        {/* Hello there! I'm  */}
        <h1 className='name'><Zoom cascade>Swapnil Vise</Zoom></h1>
        <text className='typing-content'><Typewriter
          options={{
            strings: ['Hello There! Nice To Meet You.', 'I am a Software Engineer', 'Pursuing Masters in Information Systems','At Northeastern University, Boston, MA'],
            autoStart: true,
            loop: true,
          }}
        /></text>
        {/* <h5 className="text-light">Software Developer</h5> */}
        <Fade bottom><CTA /></Fade>
        <HeaderSocials />

        {/* <div className="me">
          <img src={ME} alt="me" />
        </div> */}
        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>

    </header>
  )
}

export default Header