import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/swati.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/shubham.jpg'
import Zoom from 'react-reveal/Zoom'

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

const data = [
  {
    avatar: AVTR1,
    role: 'Tech Lead at Mphasis Ltd.',
    name: 'Swati Gaykar',
    review: 'Swapnil has worked with me for around 3 years I would say he was an asset to the team and the company as a whole. His zest to learn new technologies, accept a task with enthusiasm and aptness as per the project requirement are impressive. He is a quick learner and has good analytical skills. He leads with innovative ideas and many of his teammates find his competencies, sincerity and commitment both inspiring and motivating.                                                                                         '
  },
  {
    avatar: AVTR2,
    name: 'Snehal Khairkar',
    role: 'Senior Software Engineer at Mphasis Ltd.',
    review: "Swapnil was a fantastic developer with whom I had the pleasure of working closely on the same team for two and a half years. Swapnil's ability to try new challenges by taking ownership of innovations in new areas for the team, developing his expertise, and sharing that knowledge was one of his core strengths. He was a hardworking professional who could always be relied on to deliver both internally and with external customers. I would confidently recommend Swapnil because he is capable of handling any software development project."
  },
  {
    avatar: AVTR3,
    name: 'Shubham Kokadwar',
    role: 'Software Engineer at Mphasis Ltd.',
    review: 'I had the pleasure of working with Swapnil at Mphasis for 2.5 years. Swapnil is a passionate and dynamic developer who enjoys taking on new challenges and coming up with innovative solutions under time constraints. He is dependable and great to work with.'
  }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <Zoom top cascade><h2>What Others Say</h2></Zoom>
      <Swiper className="container testimonials__container"
        spaceBetween={40}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        >
        {
          data.map(({ avatar, name,role, review }, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                <div className="client__avatar">
                  <img src={avatar} alt='Avatar One' />
                </div>
                <h4 className='Recommender__name'>{name}</h4>
                <h5 className='Recommender__role'>{role}</h5>
                <small className='Recommender__review'>
                  {review}
                </small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials